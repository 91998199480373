import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

export function LanguageSwitcher({ filename }) {
    const language = filename.slice(-1) == 'c' ? 'c' : 'rust';

    return (
        <Paper
            elevation={0}
            spacing={0}
            sx={{
                borderRadius: '24px',
                padding: 0.5,
                backgroundColor: '#E2E2E2',
                transform: 'scale(0.85)',
            }}
        >
            <Chip
                label="C/C++"
                variant="outlined"
                sx={{
                    borderColor: language == 'c' ? '#F74E00' : '#E2E2E2',
                    color: language == 'c' ? '#F74E00' : '#828282',
                    backgroundColor: language == 'c' ? '#FFDFD0' : null,
                    borderWidth: '1px',
                    fontWeight: 500,
                    fontSize: '1rem',
                    borderRadius: '24px',
                    padding: '0 2px',
                }}
            />
            <Chip
                label="RUST"
                variant="outlined"
                sx={{
                    borderColor: language == 'rust' ? '#F74E00' : '#E2E2E2',
                    color: language == 'rust' ? '#F74E00' : '#828282',
                    backgroundColor: language == 'rust' ? '#FFDFD0' : null,
                    borderRadius: '23px',
                    fontSize: '1rem',
                    borderWidth: '1px',
                    fontWeight: 500,
                    padding: '0 2px',
                }}
            />
        </Paper>
    );
};