import * as React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Spacer } from '@mui/system';
import { HelpButton, ShareButton } from './Buttons';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Octokit, App } from "octokit";

const octokit = new Octokit({
    auth: process.env.REACT_APP_GITHUB_TOKEN
})

function Header({ codeValue, privateInput, filename }) {
    const handleShare = async () => {
        console.log('hello');
        try {
            const response = await octokit.request('POST /gists', {
                public: false,
                description: "",
                files: {
                    [filename]: {
                        content: codeValue
                    }
                },
                headers: {
                    'X-GitHub-Api-Version': '2022-11-28'
                }
            });

            console.log('Gist created successfully:', response.data);

            const gistUrl = response.data.html_url;
            window.open(gistUrl, '_blank', 'noopener,noreferrer');

        } catch (error) {
            console.error('Error creating gist:', error);
        }
    };

    return (
        <header className="app-header">
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.25}
            >
                {/* LHS of Header */}
                <Box
                    component="img"
                    src="/lita_logo.png"
                    alt="Logo"
                    sx={{ height: 60, width: 60 }}
                />
                <Chip
                    label="PLAYGROUND"
                    sx={{
                        fontSize: '10px',
                        height: '20px',
                        minWidth: 'auto',
                    }}
                />
                <HelpButton />

                {/* Spacer btwn LHS & RHS */}
                <Box sx={{ flexGrow: 1 }} />

                {/* RHS of Header */}
                <Typography
                    sx={{
                        fontSize: '0.85rem',
                        color: '#D1D1D1'
                    }}
                >
                    LANGUAGE
                </Typography>
                <Box sx={{ width: '4px' }} /> {/* Spacer */}
                <LanguageSwitcher filename={filename} />
                <Box sx={{ width: '4px' }} /> {/* Spacer */}
                <ShareButton onClick={handleShare}/>
            </Stack>
        </header>
    );
}

export default Header;
