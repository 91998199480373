import React from 'react';
import InputEditor from './InputEditor';
import OutputViewer from './OutputViewer';
import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';

function RightContainer({ privateInput, setPrivateInput, codeValue, selectedFile, codeOutput, setCodeOutput }) {
    return (
        <Stack>
            <InputEditor privateInput={privateInput} setPrivateInput={setPrivateInput} />
            <Divider />
            <OutputViewer
                privateInput={privateInput}
                codeValue={codeValue}
                selectedFile={selectedFile}
                codeOutput={codeOutput}
                setCodeOutput={setCodeOutput}
            />
        </Stack>
    );
}

export default RightContainer;
