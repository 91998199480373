import React, { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Paper, Typography, Divider, List, ListItem, ListItemText, TextField, IconButton } from '@mui/material';
import { CreateFileButton, ImportButton } from './Buttons';
import DeleteIcon from '@mui/icons-material/Delete';

function FileDrawer({ handleFileClick, selectedFile, fileContents, setFileContents }) {
    const [files, setFiles] = useState(['read-write.rs', 'reverse.c', 'checksum.c']);
    const [newFileName, setNewFileName] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [hoveredFile, setHoveredFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleAddFile = () => {
        setIsAdding(true);
    };

    const handleFileNameChange = (e) => {
        setNewFileName(e.target.value);
    };

    const handleFileNameSubmit = (e) => {
        if (e.key === 'Enter' && newFileName.trim() !== '') {
            const newFile = newFileName.trim();
            setFiles(prevFiles => {
                if (!prevFiles.includes(newFile)) {
                    return [...prevFiles, newFile];
                }
                return prevFiles;
            });
            setNewFileName('');
            setIsAdding(false);
            handleFileClick(newFile);
        }
    };

    const handleDeleteFile = (fileName) => {
        const updatedFiles = files.filter(file => file !== fileName);
        setFiles(updatedFiles);

        if (fileName === selectedFile && updatedFiles.length > 0) {
            handleFileClick(updatedFiles[0]);
        } else if (updatedFiles.length === 0) {
            handleFileClick('');
        }
    };

    const onDrop = (acceptedFiles) => {
        const fileContentsMap = {};

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                fileContentsMap[file.name] = fileContent;

                setFiles(prevFiles => {
                    if (!prevFiles.includes(file.name)) {
                        return [...prevFiles, file.name];
                    }
                    return prevFiles;
                });

                setFileContents(prevContents => ({
                    ...prevContents,
                    ...fileContentsMap
                }));

                handleFileClick(file.name, fileContent);
            };
            reader.readAsText(file);
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.c,.rs',
        multiple: true,
        directory: true
    });

    const handleDownloadFile = (fileName) => {
        const blob = new Blob([fileContents], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const newFile = uploadedFile.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                setFiles(prevFiles => {
                    if (!prevFiles.includes(newFile)) {
                        return [...prevFiles, newFile];
                    }
                    return prevFiles;
                });
                handleFileClick(newFile, fileContent);
            };
            reader.readAsText(uploadedFile);
        }
    };

    const triggerFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            {/* File Drawer Header */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    padding: '0 8px',
                    backgroundColor: '#FCFCFD'
                }}
                {...getRootProps({ className: 'dropzone' })} // Apply dropzone to the header
            >
                <ImportButton />
                <input
                    {...getInputProps({
                        webkitdirectory: 'true',
                        multiple: true
                    })}
                    style={{ display: 'none' }}
                />
                <Typography
                    sx={{
                        fontSize: '20px',
                        flexGrow: 1,
                        textAlign: 'center'
                    }}
                >
                    Files
                </Typography>
                {/* Create File Button */}
                <div onClick={(e) => { e.stopPropagation(); handleAddFile(); }}> {/* Stop event from bubbling up */}
                    <CreateFileButton />
                </div>
            </Stack>

            <Divider />

            {/* File drawer content */}
            <List>
                {files.map((fileName) => (
                    <ListItem
                        button
                        key={fileName}
                        onClick={() => handleFileClick(fileName)}
                        onMouseEnter={() => setHoveredFile(fileName)}
                        onMouseLeave={() => setHoveredFile(null)}
                        style={{
                            backgroundColor: selectedFile === fileName ? '#FFEEE5' : 'transparent',
                            padding: '4px 8px',
                        }}
                    >
                        <ListItemText
                            primary={fileName}
                            sx={{
                                textAlign: 'center',
                                color: selectedFile === fileName ? '#F74E00' : '#B9B9B9'
                            }}
                        />
                        {hoveredFile === fileName && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteFile(fileName);
                                }}
                                size="small"
                                style={{
                                    position: 'absolute',
                                    right: '8px',
                                }}
                                disableRipple
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </ListItem>
                ))}
                {isAdding && (
                    <ListItem>
                        <TextField
                            fullWidth
                            autoFocus
                            placeholder="Enter file name"
                            value={newFileName}
                            onChange={handleFileNameChange}
                            onKeyDown={handleFileNameSubmit}
                            size="small"
                        />
                    </ListItem>
                )}
            </List>
        </>
    );
}

export default FileDrawer;
