import React from 'react';
import { Box, Chip, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarIcon from '@mui/icons-material/Star';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function ImportInputButton({ onClick, size = "medium" }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="menu" onClick={onClick}>
      <UploadFileIcon fontSize={size} />
    </IconButton>
  );
}

export function MenuButton({ onClick, size = "medium" }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="menu" onClick={onClick}>
      <MenuIcon fontSize={size} />
    </IconButton>
  );
}

export function RunButton({ onClick, size = "medium" }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="run" onClick={onClick}>
      <PlayArrowIcon fontSize={size} sx={{ color: '#F74E00' }} />
    </IconButton>
  );
}

export function CreateFileButton({ onClick, size = "medium" }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="create-file" onClick={onClick} sx={{ margin: 0, padding: 0 }}>
      <AddIcon fontSize={size} />
    </IconButton>
  );
}

export function HelpButton({ onClick }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="help" onClick={onClick}>
      <HelpOutlineIcon sx={{ height: "15px", width: "15px" }} />
    </IconButton>
  );
}

export function ImportButton({ onClick }) {
  return (
    <IconButton disableRipple edge="start" color="inherit" aria-label="import" onClick={onClick}>
      <Box
        component="img"
        src="/upload_icon.png"
        alt="Upload icon"
        sx={{
          height: 25,
          width: 25,
          mr: 1
        }}
      />
    </IconButton>
  );
}

export function ProveButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      edge="start"
      color="inherit"
      aria-label="prove"
      onClick={onClick}
    >
      <Box
        component="img"
        src="/prove_icon.png"
        alt="Prove icon"
        sx={{
          height: 25,
          width: 25,
        }}
      />
    </IconButton>
  );
}

export function VerifyButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      edge="start"
      color="inherit"
      aria-label="verify"
      onClick={onClick}
    >
      <Box
        component="img"
        src="/verify_icon.png"
        alt="Verify icon"
        sx={{
          height: 25,
          width: 25,
        }}
      />
    </IconButton>
  );
}

export function ShareButton({ onClick }) {
  return (
    <Button
      sx={{
        textTransform: 'none',
        backgroundColor: '#F74E00',
        color: '#FFFFFF',
        padding: '4px 8px',
        borderRadius: 1
      }}
      onClick={onClick}
    >
      <Box
        component="img"
        src="/share_icon.png"
        alt="Share icon"
        sx={{
          height: 15,
          width: 15,
          mr: 1
        }}
      />
      Share
    </Button>
  )
}

export function DownloadButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      edge="start"
      color="inherit"
      aria-label="download"
      onClick={onClick}
    >
      <Box
        component="img"
        src="/download_icon.png"
        alt="Download icon"
        sx={{
          height: 30,
          width: 30,
        }}
      />
    </IconButton>
  );
}