import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';
import { basicSetupOptions, lightTheme, StyledTextField } from '../static';
import { ImportInputButton } from './Buttons';

// TODO: add functionality to import button
function InputEditor({ privateInput, setPrivateInput }) {
    const onPrivateInputChange = React.useCallback((event) => {
        setPrivateInput(event.target.value);
    }, []);

    return (
        <Stack sx={{ backgroundColor: '#FCFCFD' }}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='0 8px'
            >
                <Typography sx={{ color: "#000000" }} variant="h6">
                    Inputs
                </Typography>
                <ImportInputButton />
            </Stack>
            <Box sx={{ height: '1px' }} /> {/* Spacer, prevents off by 1 */}

            <Divider />

            <StyledTextField
                variant="outlined"
                placeholder="type here to add inputs"
                fullWidth
                multiline
                rows={12}
                onChange={onPrivateInputChange}
            />
        </Stack>
    );
}

export default InputEditor;