import React from 'react';
import './App.css';
import Typography from '@mui/material/Typography';
import Header from './components/Header';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CodeEditor from './components/CodeEditor';
import RightContainer from './components/RightContainer';
import { codeExamples } from './static';

function App() {
    const [privateInput, setPrivateInput] = React.useState('');
    const [codeOutput, setCodeOutput] = React.useState('');
    const [codeValue, setCodeValue] = React.useState(codeExamples['read-write.rs']);
    const [selectedFile, setSelectedFile] = React.useState('read-write.rs');

    return (
        <Box sx={{ height: '100vh' }}>
            <Header codeValue={codeValue} privateInput={privateInput} filename={selectedFile} />
            <Divider />
            <Grid container sx={{ height: 'calc(100% - 64px)' }}>
                <Grid item xs={8} sx={{ borderRight: '1px solid', borderColor: 'divider', height: '100%' }}>
                    <CodeEditor
                        privateInput={privateInput}
                        codeValue={codeValue}
                        setCodeValue={setCodeValue}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        setCodeOutput={setCodeOutput}
                    />
                </Grid>
                <Grid item xs={4} sx={{ height: '100%' }}>
                    <RightContainer
                        privateInput={privateInput}
                        setPrivateInput={setPrivateInput}
                        codeValue={codeValue}
                        selectedFile={selectedFile}
                        codeOutput={codeOutput}
                        setCodeOutput={setCodeOutput} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default App;
